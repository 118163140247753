import styled from 'styled-components'
import media from 'styled-media-query'

export const Box = styled.div`
    width: 100%;
    height: 40%;
    display: block;
    background-color: #fff;
    background-image: url(${ props => props.img }); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    border-bottom-right-radius: 150px;

    position: absolute;
    z-index: 10;
    top: 0;

    ${media.lessThan("medium")`
        background-image: none; 
    `}
    
`

export const Title = styled.div`
    position: relative;
    z-index: 100;

    text-align: center;

    margin: 40px 0 150px 0;
    
    h2 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 3rem;
        font-weight: 700;
        color: #0C8D85;

        text-transform: uppercase;
    }

    ${media.lessThan("medium")`
        margin: 40px 0 50px 0;

        h2 {
            font-size: 2rem;
        }
    `}

`