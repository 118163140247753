import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from '../Navbar'
import Footer from '../Footer'
import WhatsAppButton from '../WhatsAppButton'

import GlobalStyles from '../../styles/global'
import * as S from './styled'

const Layout = ({ children, title }) => {

    const { navbarImage } = useStaticQuery(
        graphql`
            query {
                navbarImage: file (relativePath: { eq: "navbar-image.png" }) {
                  childImageSharp {
                    fluid(quality: 100) {
                        src
                        ...GatsbyImageSharpFluid
                    }
                  }
                } 
              }
        `
    )

    return (
        <>
            <GlobalStyles />
            <Navbar textColor />
            <WhatsAppButton />
            <S.Box img={navbarImage.childImageSharp.fluid.src} />
            <S.Title>
                <h2>{ title }</h2>
            </S.Title>
            <main>{children}</main>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
