import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Diseases from "../components/Diseases"

const DiseasesPage = () => {

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query DiseasesList {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(doencas)/"  }}) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
              description
              highlightTitle
              title
              img {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = allMarkdownRemark.edges

  return (
    <Layout title="Doenças da coluna">
      <SEO title="Home" />
      <Diseases posts={data} />
    </Layout>
  )
}

export default DiseasesPage