import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import * as S from "./styled"

const Diseases = ({ posts }) => {

    return (
        <>
            <S.CardsContainer>
                {
                    posts.map(({node: { id, fields: { slug }, frontmatter: { description, title, highlightTitle, img } }}) => {
                        return (
                            <Link to={`/doencas${slug}`}>
                                <S.Card key={id}>
                                    <S.Img>
                                        <Img fluid={img.childImageSharp.fluid}  />
                                    </S.Img>
                                    <h4>{title} <br /><b>{highlightTitle}</b></h4>
                                    <hr />
                                    <S.CardP>
                                        <p>{description}</p>
                                    </S.CardP>
                                    <span>Ler mais</span>
                                </S.Card>
                            </Link>
                        )
                    })
                }
            </S.CardsContainer>
        </>
    )
}

Diseases.propTypes = {
    posts: PropTypes.array.isRequired,
}

export default Diseases